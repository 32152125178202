//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { orderBy } from "~/util/helpers";
import CategoryProduct from "~/components/products/CategoryProduct.vue";
import { sendYandexMetric } from "~/service/ecommerce";

export default {
    name: "CategoryCatalog",
    components: {
        CategoryProduct,
        Swiper,
        SwiperSlide,
    },
    props: ["category"],
    data() {
        return {
            activeTagsId: [],
            activeAntiTagsId: [],
            // generalTagsActive: [],
            generalTagActive: null,
            swiperOption: {
                slidesPerView: "auto",
                spaceBetween: 0,
                freeMode: true,
                pagination: { clickable: true },
            },
        };
    },
    computed: {
        ...mapState("products", ["products", "currentViewCategoryId", "currentSort", "anotherMenu", "catalogView"]),
        ...mapState("metrika", ["paramsAB"]),
        // ...mapGetters("cart", ["closestDepartments"]),
        checkCatalogView() {
            return this.catalogView.view === "list" && this.oneColumnCatalog;
        },
        oneColumnCatalog() {
            return this.catalogView.screenWidth <= 580;
        },
        generalTags() {
            return this.category.tags.filter((tag) => tag.is_general);
        },
        isCurrentCategory() {
            return this.currentViewCategoryId === this.category.id;
        },
        uniqueNameProducts() {
            return this.products.filter((product, index, self) => {
                return index === self.findIndex((p) => p.name === product.name);
            });
        },
        productsByCategory() {
            return this.uniqueNameProducts.filter((item) => {
                return item.categories_ids?.includes(this.category.id);
            });
        },
        needFilterProducts() {
            return this.activeTagsId.length || this.activeAntiTagsId.length || this.generalTagActive;
        },
        filtredProducts() {
            return this.productsByCategory.filter((product) => {
                const matchTagFilter = this.activeTagsId.every((tagId) => product.tags?.includes(tagId));
                const matchAntiTagFilter = this.activeAntiTagsId.some((tagId) => product.tags?.includes(tagId));
                let matchTagFilterGeneral = product.tags?.includes(this.generalTagActive);

                if (!this.generalTagActive) {
                    matchTagFilterGeneral = true;
                }
                // let matchTagFilterGeneral = this.generalTagsActive.every(tagId => product.tags?.includes(tagId));
                return matchTagFilter && !matchAntiTagFilter && matchTagFilterGeneral;
            });
        },
        sortedProducts() {
            let productsArr = this.needFilterProducts ? this.filtredProducts : this.productsByCategory;

            const sortType = this.currentSort;
            const generalTagsByPopularity = this.generalTagsByPopularity;

            if (sortType === "popularity") {
                productsArr = productsArr.sort((a, b) => {
                    const tagA = generalTagsByPopularity.find((generalTag) =>
                        a.tags.some((tag) => tag === generalTag.id)
                    );
                    const tagB = generalTagsByPopularity.find((generalTag) =>
                        b.tags.some((tag) => tag === generalTag.id)
                    );

                    if (!tagA) {
                        return 1;
                    }

                    if (!tagB) {
                        return -1;
                    }

                    if (tagA.id === tagB.id) {
                        return b.rating - a.rating;
                    }

                    return tagB.rating - tagA.rating;
                });
                // productsArr = orderBy(
                //     productsArr,
                //     'rating',
                //     'desc',
                // );
            } else if (sortType === "priceUp") {
                productsArr = orderBy(productsArr, "price", "asc");
            } else if (sortType === "priceDown") {
                productsArr = orderBy(productsArr, "price", "desc");
            } else if (sortType === "new") {
                productsArr = orderBy(productsArr, "sort_order", "desc");
            } else if (sortType === "evaluation") {
                const productWithScore = orderBy(
                    productsArr.filter((item) => item.scores_count > 10),
                    "avg_score",
                    "desc"
                );
                const productWithoutScore = productsArr.filter(
                    (item) => !item.scores_count || item.scores_count <= 10
                );
                productsArr = productWithScore.concat(productWithoutScore);
            } else if (sortType === "personal_score") {
                productsArr = orderBy(productsArr, "personal_score", "desc");
            }

            const bannerCard = productsArr.find((elem) => elem.is_banner_card);
            if (bannerCard) {
                return [bannerCard, ...productsArr.filter((elem) => elem !== bannerCard)];
            }

            return productsArr;
        },
        categoryTitle() {
            return this.category.id !== this.anotherMenu
                ? this.category.title
                : this.category.title.replace(/у/gi, "<span class='revert'>у</span>");
        },
        notEmptyGeneralTags() {
            return this.generalTags.filter((tag) =>
                this.productsByCategory.some((product) => product.tags?.includes(tag.id))
            );
        },
        generalTagsByPopularity() {
            const tagsWithRaiting = this.notEmptyGeneralTags.map((tag) => {
                const productsByTag = this.productsByCategory.filter((product) => product.tags.includes(tag.id));

                const rating = productsByTag.length
                    ? productsByTag.reduce((accum, curr) => accum + curr.rating, 0)
                    : 0;

                return {
                    ...tag,
                    rating: Math.round(rating),
                };
            });

            return tagsWithRaiting.sort((a, b) => b.rating - a.rating);
        },
        sortedGeneralTags() {
            if (this.generalTags.length < 2) {
                return [];
            }

            if (this.notEmptyGeneralTags.length < 2) {
                return [];
            }

            if (this.currentSort === "popularity") {
                return this.generalTagsByPopularity;
            }

            return this.notEmptyGeneralTags;
        },
    },
    methods: {
        showMiniCart(ev) {
            this.$emit("showMiniCart", ev);
        },
        hideMiniCart() {
            this.$emit("hideMiniCart");
        },
        toggleMiniCart(ev) {
            this.$emit("toggleMiniCart", ev);
        },
        handleTag(tagId) {
            if (this.activeTagsId.includes(tagId)) {
                this.activeTagsId = this.activeTagsId.filter((id) => id !== tagId);
            } else {
                if (this.activeAntiTagsId.includes(tagId)) {
                    this.handleAntiTag(tagId);
                }
                this.activeTagsId.push(tagId);

                sendYandexMetric("add_ingredient", { idOfInredient: tagId });
            }
        },
        handleAntiTag(tagId) {
            if (this.activeAntiTagsId.includes(tagId)) {
                this.activeAntiTagsId = this.activeAntiTagsId.filter((id) => id !== tagId);
            } else {
                if (this.activeTagsId.includes(tagId)) {
                    this.handleTag(tagId);
                }
                this.activeAntiTagsId.push(tagId);
                sendYandexMetric("remove_ingredient", { idOfInredient: tagId });
            }
        },
        handleGeneralTags(tagId) {
            if (this.generalTagActive === tagId) {
                this.generalTagActive = null;
            } else {
                this.generalTagActive = tagId;
            }
        },
        choosenGeneralTag(tagId) {
            return this.generalTagActive === tagId;
        },
    },
};
