//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import cityLinksHelper from "~/util/city-links-helper";
import Product from "~/models/Product";
import { numberWithSpaces, orderBy, throttle, webpToJpg } from "~/util/helpers";
import ProductTags from "~/components/products/ProductTags.vue";

export default {
    name: "CategoryProduct",
    components: { ProductTags },
    props: ["product", "tags", "routeIsHome"],
    data() {
        return {
            // isList: false,
            // colorProduct: null,
            loadedImage: false,
        };
    },
    computed: {
        ...mapState("metrika", ["paramsAB"]),
        ...mapState("products", ["catalogView"]),
        ...mapGetters("cart", ["isCartLoaded", "productsInCart", "closestDepartments"]),
        ...mapState("cart", ["content"]),

        checkCatalogView() {
            return this.catalogView.view === "list" && this.oneColumnCatalog;
        },
        photo() {
            return new Product(this.product).photoSmall;
        },
        productLink() {
            const refresh = this.$store.state.products.refresh;

            return cityLinksHelper.getNuxtLinkToPath(
                this.$catalogRouter.getProductPath(this.product),
                cityLinksHelper.getCityIdFromVuex(this.$store)
            );
        },
        // timeOut() {
        //     if (process.client) {
        //         return Math.trunc(Math.abs(this.$refs.productCard.offsetTop - window.pageYOffset) / 10);
        //     } // Время задержки будет зависеть от удалённости карточки от видимой области пользователя
        //     return 0;
        // },
        oneColumnCatalog() {
            return this.catalogView.screenWidth <= 580;
        },
        productCount() {
            const products = this.productsInCart.filter((product) => product.id === this.product.id);

            let count = null;

            products.forEach((product) => {
                count += product.quantity;
            });

            return count;
        },
        productInCart() {
            if (this.productCart) {
                return this.productCart;
            } else {
                return this.content?.products?.find((prod) => {
                    const cartProdModIds = orderBy(
                        (prod.sel_modifiers || []).map((m) => (typeof m.id === "string" ? parseInt(m.id) : m.id))
                    ).join("_");
                    const payloadModIds = orderBy(
                        (this.sel_modifiers || this.modifiers || []).map((m) =>
                            typeof m.id === "string" ? parseInt(m.id) : m.id
                        )
                    ).join("_");

                    return this.product.id == prod.id && payloadModIds == cartProdModIds;
                });
            }
        },
        getSelectedModifiersQuantity() {
            const modifiedProducts = [];
            this.productsInCart.forEach((product) => {
                if (product.id === this.product.id && product.sel_modifiers?.length) {
                    modifiedProducts.push(product);
                }
            });
            let sum = 0;
            modifiedProducts.forEach((product) => {
                sum += product.quantity;
            });
            return sum;
        },
        productIsDisabled() {
            if (!this.product.remainings || !this.closestDepartments?.length) {
                return;
            }

            return this.closestDepartments.every((department) => {
                return (
                    (this.product.remainings?.[department.id] !== null && department.active) || !department.active
                );
            });
        },
        jpgPhoto() {
            return webpToJpg(this.photo);
        },

        isShowProductModBtn() {
            return (
                this.product.modifier_groups.some((mod) => mod.assemble_yourself_combo) ||
                this.product.modifier_groups.some((mod) => !mod.is_complement) ||
                this.product.available_modifiers.some((mod) => mod.consider_price)
            );
        },
    },
    mounted() {
        // this.isList = this.catalogView === 'list';
        document.body.addEventListener(
            "resize",
            throttle(() => {
                this.oneColumnCatalog = window.matchMedia("(max-width: 580px)").matches;
            }, 500)
        );
        if (this.catalogView?.screenWidth && window.innerWidth > 580) {
            this.resetCatalogView();
        }
        // this.randomColor();
    },
    methods: {
        ...mapActions("modals", ["openModal"]),
        ...mapMutations("productPopup", ["setOpenIn"]),
        async onProductClick() {
            this.setOpenIn(this.$route.path);
            if (this.routeIsHome) {
                await this.$router.push({ path: this.productLink });
                return;
            }
            this.showProductPopup();
        },
        showMiniCart(ev) {
            this.$emit("showMiniCart", ev);
        },
        hideMiniCart() {
            this.$emit("hideMiniCart");
        },
        toggleMiniCart(ev) {
            this.$emit("toggleMiniCart", ev);
        },
        formatValue(value) {
            return numberWithSpaces(value);
        },

        resetCatalogView() {
            this.$cookies.remove("userScreenWidth", { "max-age": -1 });
            this.$cookies.remove("viewCatalog", { "max-age": -1 });
            this.$store.dispatch("products/changeViewCatalog", { view: "tile", screenWidth: null }, { root: true });
        },
        showProductPopup() {
            this.$store.dispatch("productPopup/showProductByPayload", {
                product: this.product,
                openIn: this.$route.path,
            });
        },
    },
};
